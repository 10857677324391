import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../helpers/auth';
import { RootState } from '../reduxToolkit/store';
import { AuthWrapper } from './AuthWrapper';

export const AdminLayout: React.FC = (props) => {
  const { children } = props;

  const user = useSelector((state: RootState) => state.common.user)

  const navigate = useNavigate();

  function logout() {
    removeToken();
    navigate('/login');
  }

  return (
    <AuthWrapper>
      <main style={{ background: 'rgb(34, 37, 52)', minHeight: '100vh' }} className='w-screen flex flex-col justify-between'>
        {children}
        <div className='w-full flex flex-col text-center pb-4 text-white text-gray-500'>
          Angemeldet als {user?.name ?? user?.email}{" "}
          <span className='cursor-pointer text-gray-400 hover:text-white transition transition-duration-100' onClick={logout}>Abmelden</span>
        </div>
      </main>
    </AuthWrapper>
  );
};
