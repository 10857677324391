import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { getAdminTimeTracks } from "../../../services"  
import ErrorIcon from '@mui/icons-material/Error';
import { TimeTrackPopulated } from "../../../shared/interfaces/common.interface" 
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; 
import GppBadIcon from '@mui/icons-material/GppBad';

interface AdminEntryListProps {
    userId?: string,
    date?: Date
}
export const AdminEntryList: React.FC<AdminEntryListProps> = (props) => {
    const { userId, date } = props
    const [isLoading, setIsLoading] = useState(false)
    const [timeTracks, setTimeTracks] = useState<TimeTrackPopulated[]>([])

    useEffect(() => {
        fetch()
    }, [userId, date])

    async function fetch() {
        setIsLoading(true)
        try {
            const tracks = await getAdminTimeTracks(userId, date) ?? []; 

            setTimeTracks(tracks.filter((t) => t.user?.email))
        } catch (err) {
            console.error(err)
        }
        setIsLoading(false)
    }

    const DateRender = (date: Date) => {
        if (!date) return '-'
        return format(new Date(date.toString()), 'dd.MM.yyyy HH:mm')
    }

    function getSyncStatus(entry: TimeTrackPopulated){ 
        if(!entry?.user?.personioUserId) return <Tooltip title="Nicht möglich - Der Nutzer ist nicht mit Personio verknüpft"><GppBadIcon /></Tooltip>
        if(!entry.personioId && entry.personioError) return <Tooltip title={`Fehler beim Personio Sync: ${entry.personioError}`}><ErrorIcon /></Tooltip>
        if(entry.personioId) return <CheckCircleIcon htmlColor="green" />
    }

    return (
        <div className="flex justify-center items-center overflow-auto" style={{ height: '36rem' }}>
            {!isLoading &&
                <>
                    {timeTracks?.length > 0 &&
                        <TableContainer className="h-full">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Benutzer</TableCell>
                                        <TableCell align="left">Arbeitsbeginn</TableCell>
                                        <TableCell align="left">Arbeitsende</TableCell>
                                        <TableCell align="left">PersonioSync</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {timeTracks.map((track) => (
                                        <TableRow
                                            key={track._id}
                                        >
                                            <TableCell align="left">
                                                {track?.user?.name ?? track?.user?.email}
                                            </TableCell>
                                            <TableCell align="left">{DateRender(track?.datetime_start)}</TableCell>
                                            <TableCell align="left">{DateRender(track?.datetime_end)}</TableCell>
                                            <TableCell align="left">{getSyncStatus(track)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                    {timeTracks?.length < 1 && <span>Es gibt keine Einträge</span>}
                </>
            }
            {isLoading && <CircularProgress />}
        </div>
    )
}
