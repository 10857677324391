import { TimeTrackingCard } from '../components/dashboard/TimeTrackingCard';
import TrackingUsersCard from '../components/dashboard/TrackingUsersCard';
import { AdminLayout } from '../layouts/AdminLayout';

export const DashboardPage: React.FC = () => {
  return (
    <AdminLayout>
      <div className='h-full w-full flex flex-col justify-center items-center my-4'>
        <img src="/stuzubi-logo.png" width="200" />
        <TimeTrackingCard />
        <div className='mt-4' />
        <TrackingUsersCard />
      </div>
    </AdminLayout>
  );
};
