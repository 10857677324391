import { Dialog, MenuItem, TextField } from "@mui/material"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { getAllUsers } from "../../../services"
import { PartialUser } from "../../../shared/interfaces/common.interface"
import { TEXTS } from "../../../texts/german"
import { CommonButton } from "../../common/CommonButton"
import { CommonCard } from "../../common/CommonCard"
import { AdminEntryList } from "./AdminEntryList"


export const AdminButton: React.FC = (props) => {
    const [showDialog, setShowDialog] = useState(false)
    const [users, setUsers] = useState<PartialUser[]>([])

    const [filterUser, setFilterUser] = useState<string>()
    const [filterDate, setFilterDate] = useState<Date>()


    useEffect(() => {
        fetch()
    }, [showDialog])

    async function fetch() {
        if (!showDialog) return;
        try {
            const users = await getAllUsers()
            setUsers(users)
        } catch (err) {
            console.error(TEXTS.ERROR_OCCURED)
        }
    }

    function handleOpenDialog() {
        setShowDialog(true)
    }

    function handleCloseDialog() {
        handleClearFilters()
        setShowDialog(false)
    }

    function handleClearFilters() {
        setFilterUser(undefined)
        setFilterDate(undefined)
    }

    return (
        <>
            <CommonButton style={{ color: "black" }} title='Admin' onClick={handleOpenDialog} />
            <Dialog open={showDialog} onClose={handleCloseDialog} fullWidth>
                <CommonCard
                    actions={(
                        <div className="flex space-x-2">
                            <TextField
                                type="date"
                                label="Datum"
                                InputLabelProps={{ shrink: true }}
                                value={filterDate ? format(filterDate,'yyyy-MM-dd'):'yyyy-MM-dd'}
                                onChange={(e) => setFilterDate(new Date(e.target.value))}
                            />
                            <TextField
                                id="select-user"
                                select
                                label="Nutzer"
                                className="w-44"
                                onChange={(e) => setFilterUser(e.target.value)}
                            >
                                {users.map((user) => (
                                    <MenuItem key={user._id} value={user._id}>
                                        {user.email}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <CommonButton disabled={!filterUser && !filterDate} style={{ color: "black" }} title='Zurücksetzen' onClick={handleClearFilters} />
                        </div>)}
                >
                    <AdminEntryList userId={filterUser} date={filterDate} />
                </CommonCard>
            </Dialog>
        </>
    )
}