import { Button, ButtonProps, Card, CardProps, CardTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface CommonButtonInterface extends ButtonProps {
    title?: string;
}
export const CommonButton: React.FC<CommonButtonInterface> = (props) => {
    const { title,disabled } = props

    return (
        <Button
            {...props}
            style={{ background: disabled ?'lightgrey':'#aafc4c', color: 'white', paddingRight: '1.5rem', paddingLeft: '1.5rem', ...props.style }}
        >
            {title}
        </Button>
    )
};
