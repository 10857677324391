import { isTokenSet, setToken } from '../helpers/auth';

export function useAuth() {
  function checkAuthFromLocalStorage(): boolean {
    return !!isTokenSet();
  }

  return {
    checkAuthFromLocalStorage,
  };
}
