import { ButtonProps, CircularProgress, StandardTextFieldProps, TextField } from '@mui/material';
import { CommonCard } from '../common/CommonCard';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import CloseIcon from '@mui/icons-material/Close';
import { CommonButton } from '../common/CommonButton';
import { useSelector } from 'react-redux';
import { format } from "date-fns"
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import { useTimeTrack } from '../../hooks/useTimeTrack';
import { RootState } from '../../reduxToolkit/store';
import { TEXTS } from '../../texts/german';
import { abortTracking } from '../../services';
import { useEffect, useState } from 'react';
import { AdminButton } from './admin/AdminButton';

interface EntryInputProps extends StandardTextFieldProps {
  topLabel: string,
  name: "datetime_start" | "datetime_end"
}
interface TrackingProps {
  datetime_start: string,
  datetime_end: string
}
export const TimeTrackingCard: React.FC = () => {

  const activeTimeTrack = useSelector((state: RootState) => state.common.activeTimeTrack)
  const user = useSelector((state: RootState) => state.common.user)

  const [isLoading, setIsLoading] = useState(false);
  const [currentTimeSpent, setCurrentTimeSpent] = useState<string>()

  const { register, handleSubmit, reset, watch } = useForm<TrackingProps>();
  const { _startTracking, _stopTracking, formatDistanceToHoursMinutes, _getActiveTimeTrack, calculateTotalTimeSpent } = useTimeTrack()

  const currentDate = new Date()

  useEffect(() => {
    if (!activeTimeTrack) return;
    
    getTotalTimeSpent()
    let interval = setInterval(() => {
      getTotalTimeSpent()
    }, 30000)

    return () => clearInterval(interval);

  }, [activeTimeTrack, currentDate.getMinutes()])


  async function getTotalTimeSpent() {
    const totalTime = await calculateTotalTimeSpent()
    const formattedTimeSpent = formatDistanceToHoursMinutes(totalTime || 0)
    setCurrentTimeSpent(formattedTimeSpent)
  }

  async function startTracking() {
    setIsLoading(true);
    try {
      await _startTracking()
      reset()
    } catch (err) {
      toast(TEXTS.ERROR_OCCURED, { type: "error" })
    }
    setIsLoading(false);
  }

  async function stopTracking(props: TrackingProps) {
    const { datetime_start, datetime_end } = props
    _stopTracking(datetime_start, datetime_end,
      () => { toast(TEXTS.RECORDED_SUCCESSFULLY, { type: "success" }) },
      (message) => { toast(message ?? TEXTS.ERROR_OCCURED, { type: "error" }) })
  }

  async function abort() {
    if (!activeTimeTrack?._id) return;
    setIsLoading(true);
    try {
      await abortTracking(activeTimeTrack?._id);
      await _getActiveTimeTrack();
    } catch (err) {
      toast(TEXTS.ERROR_OCCURED, { type: "error" })
    }
    setIsLoading(false);
  }

  const StartButton = () => (
    <CommonButton
      title={TEXTS.START}
      onClick={startTracking}
      style={{ color: "black" }}
      startIcon={<PlayCircleFilledWhiteIcon htmlColor='black' />}>
    </CommonButton>
  )

  const StopButton = (props: ButtonProps) => (
    <CommonButton
      {...props}
      title={TEXTS.STOP}
      style={{ background: '#ff4d4f' }}
      startIcon={<PauseCircleFilledIcon />}>
    </CommonButton>
  )

  const AbortButton = (props: ButtonProps) => (
    <CommonButton
      {...props}
      title={"Abbrechen"}
      style={{ background: 'grey' }}
      startIcon={<CloseIcon />}>
    </CommonButton>
  )

  const EntryInput = (props: EntryInputProps) => (
    <div className='flex flex-col w-full'>
      <span>{props.topLabel}</span>
      <TextField
        {...props}
        {...register(props.name ?? "")}
        disabled
        inputProps={{
          step: "1"
        }}
        variant='outlined' />
    </div>
  )

  const CardActions = () => {
    if (!user?.isAdmin) return null
    return <AdminButton />
  }

  const isTracking = !!activeTimeTrack

  const defaultTime = watch("datetime_start") ? watch("datetime_start") : activeTimeTrack?.datetime_start ? format(new Date(activeTimeTrack?.datetime_start), "HH:mm:ss") : null

  return (
    <CommonCard
      style={{ maxWidth: '500px' }}
      title={TEXTS.TIME_TRACKING}
      actions={<CardActions />}
    >
      <div className='flex flex-col transition-all' style={{ color: 'rgb(107 114 128)' }}>
        <form onSubmit={handleSubmit(stopTracking)}>
          {isTracking && (
            <>
              <div className='flex justify-between p-4'>
                <span>{TEXTS.TIME_SPENT}</span>
                <span>{currentTimeSpent}</span>
              </div>
              <hr className='border-gray-300' />
              <div className='flex justify-between p-4 space-x-4 transition-all'>
                <EntryInput topLabel={TEXTS.BEGINNING} name='datetime_start' type="time" defaultValue={defaultTime} />
                <EntryInput topLabel={TEXTS.END} name='datetime_end' type="time" />
              </div>
            </>
          )}
          {isLoading ? <CircularProgress /> : <div className='flex flex-row justify-between p-4'>
            {isTracking && <AbortButton onClick={abort} />}
            {isTracking ? <StopButton type='submit' /> : <StartButton />}

          </div>}
        </form>
      </div>
    </CommonCard>
  )
};
