export const TEXTS = {
    ERROR_OCCURED: "Es ist ein Fehler aufgetreten",
    RECORDED_SUCCESSFULLY: "Erfolgreich gespeichert",
    START: "Start",
    STOP: "Stop",
    BEGINNING: "Anfang",
    END: "Ende",
    TIME_SPENT: "Zeit verbracht",
    LOGIN: "Anmeldung",
    SIGNUP: "Anmelden",
    LOGOUTT: "Ausloggen",
    MESSAGE_SIGNUP_SUCCESS: "Ihr Konto wurde erfolgreich erstellt",
    TIME_TRACKING:"Zeiterfassung"
}