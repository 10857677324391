import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartialUser, TimeTrack } from '../../shared/interfaces/common.interface';

export interface CommonState {
    user?: PartialUser,
    activeTimeTrack?: TimeTrack
}

const initialState: CommonState = {
    user: undefined,
    activeTimeTrack: undefined,
};

const commonSlice = createSlice({
    name: 'timetracking',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<PartialUser>) => {
            state.user = action.payload;
        },
        setActiveTimeTrack: (state, action: PayloadAction<TimeTrack|undefined>) => {
            state.activeTimeTrack = action.payload;
        },
    },
});

export const { setUser, setActiveTimeTrack } = commonSlice.actions;

export default commonSlice.reducer;
