import { createTheme } from '@mui/material';

// Create a theme instance.
export const materialTheme = createTheme({
  palette: {
    primary: {
      main: '#aafc4c',
    },
    secondary: {
      main: '#aafc4c',
    },
    error: {
      main: '#fc0f03',
    },
    background: {
      default: '#fff',
    },
  },
});
