import { Card, CardProps } from '@mui/material';

interface CommonCardInterface extends CardProps {
  title?: string;
  subtitle?: string; 
  actions?: JSX.Element
}
export const CommonCard: React.FC<CommonCardInterface> = (props) => {
  const { title, subtitle, children, actions } = props

  return (
    <Card 
    {...props} 
    variant='outlined' 
    style={{ height: 'auto', width: '100%',overflow:'visible', ...props.style }}
    >
      <div className='flex flex-col'>
        {(title || actions) && (
          <>
            <div className='flex flex-col p-4'>
            <div className="flex flex-row justify-between">
            <span>{title}</span> 
            {actions} </div>
            {subtitle && <span className='text-xs mt-1 text-gray-500'>{subtitle}</span>}
            </div>
            <hr className='border-gray-300' />
          </>
        )}
        {children}
      </div>
    </Card>
  )
};
