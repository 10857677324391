import {useEffect} from "react";
import {getToken} from "../services";
import {useNavigate, useSearchParams} from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../helpers/auth";
import { setUser } from "../reduxToolkit/slices/commonSlice";
import { RootState } from "../reduxToolkit/store";

export const Oauthcallback: React.FC = () => {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();  
    const dispatch = useDispatch();  

    const user = useSelector((state: RootState) => state.common.user)
    
    useEffect(() => {
        getT()
    }, []); 

    useEffect(() => {
        if(user) navigate('/dashboard'); 
    }, [user])

    async function getT() { 
        try { 
        let reTo = await getToken(searchParams.get("code") || "")
        console.log(reTo)  
        if(reTo?.access_token){ 
            const { user, access_token } = reTo; 
            setToken(access_token);
            dispatch(setUser(user)); 
            navigate('/dashboard');
        } else { 
            // Show error toast 
            console.error("Response is missing access token")
        }
        } catch(err){ 
            console.error(err); 
            navigate('/login')
        }
    }

    return <CircularProgress />
};
