import DashboardIcon from '@mui/icons-material/Dashboard';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { materialTheme } from './components/theme/MaterialTheme';
import { IndexPage } from './pages';
import { DashboardPage } from './pages/dashboard';
import { LoginPage } from './pages/login';
import { NotFound } from './pages/notFound';
import { Oauthcallback } from './pages/oauthcallback';
import store from './reduxToolkit/store';

export interface Route {
  path: string;
  title: string;
  component: JSX.Element;
  icon?: any;
  showInMenu: boolean;
}

export const routes = [
  {
    path: '/',
    element: <IndexPage />,
    title: 'vystem',
    showInMenu: false,
  },
  {
    path: '/login',
    element: <LoginPage />,
    title: 'Login',
    showInMenu: false,
  },
  {
    path: '/oauth/callback',
    element: <Oauthcallback />,
    title: 'Login',
    showInMenu: false,
  },
  {
    path: '/dashboard',
    element: <DashboardPage />,
    title: 'Dashboard',
    showInMenu: true,
    icon: <DashboardIcon />,
  },
];

function App() {
  return (
    <ToastProvider placement="bottom-center" autoDismiss={5000}>
      <Provider store={store}>
        <ToastContainer />
        <ThemeProvider theme={materialTheme}>
          <Router>
            <Routes>
              {routes.map((route) => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </Provider>
    </ToastProvider>
  );
}

export default App;
