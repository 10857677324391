import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VyLoader } from '../components/common/VyLoader';
import { removeToken, setTokenFromLocalStorage } from '../helpers/auth';
import { useAuth } from '../hooks/useAuth';
import { userAuth } from '../services';
import { setUser } from '../reduxToolkit/slices/commonSlice';
import { useDispatch } from 'react-redux';

interface AuthWrapperProps {
    redirectToDashboard?: boolean;
    redirectToLogin?: boolean;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = (props) => {
    const { redirectToDashboard, redirectToLogin = true } = props;

    const navigate = useNavigate();
    const { checkAuthFromLocalStorage } = useAuth();
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkAuthAndToken();
    }, []);

    async function checkAuthAndToken() {
        setTokenFromLocalStorage()

        function onError() {
            removeToken();
            setIsLoading(false);
            return redirectToLogin && navigate('/login');
        }

        try {
            const user = await userAuth();
            setIsLoading(false);
            if (user) {
                dispatch(setUser(user))
                return navigate('/dashboard');
            } else {
                onError()
            }
        } catch (err) {
            onError()
        }
        const auth = checkAuthFromLocalStorage();
        if (!auth && redirectToLogin) return navigate('/login');
        if (auth && redirectToDashboard) return navigate('/dashboard');
        setIsLoading(false);
    }

    if (isLoading) return <VyLoader solidBackground={true} />;

    return <>{props.children}</>;
};
