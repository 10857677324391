import http from './helpers/http';
import { PartialUser, TimeTrack, TimeTrackPopulated } from './shared/interfaces/common.interface';
import axios from 'axios'

interface StuResponse<T> {
    status: 'ok' | 'error';
    data: T;
}

export async function goSSO(): Promise<string> {
    const { data: response } = await http.get('user/auth/gosso',);
    return response.data;
}

interface GetAuthData {
    user: PartialUser;
    role: {
        name: string;
        level: number;
    };
}

export async function getAuth(): Promise<GetAuthData> {
    const { data: response } = await axios.post('auth/get');
    return response.data;
}

export async function getToken(code: string): Promise<any> {
    const { data: response } = await http.post('user/auth/token', { code });
    return response;
}

export async function getUsersByEmail(email: string): Promise<PartialUser[]> {
    const { data: response } = await http.post('user/getall', {
        limit: 1000,
        filter: email,
    });
    return response.data;
}

export async function userLogin(email: string, password: string): Promise<{ user: PartialUser, access_token: string }> {
    const { data: response } = await axios.post('user/login', {
        email,
        password,
    });
    return response;
}

export async function userSignup(email: string, password: string): Promise<PartialUser> {
    const { data: response } = await axios.post('user/signup', {
        email,
        password,
    });
    return response;
}

export async function userAuth(): Promise<PartialUser> {
    const { data: response } = await axios.get('user/auth');
    return response;
}

export async function getAllUsers(): Promise<PartialUser[]> {
    const { data: response } = await axios.get('user/all');
    return response;
}

export async function getActiveTimeTrack(): Promise<TimeTrack> {
    const { data: response } = await axios.get(`timetrack/active`);
    return response;
}

export async function getCurrentDateTimeTracks(): Promise<TimeTrack[]> {
    const { data: response } = await axios.get(`timetrack/today/all`);
    return response;
}

export async function getAllActiveTimeTracks(): Promise<TimeTrackPopulated[]> {
    const { data: response } = await axios.get(`timetrack/active/all`);
    return response;
}

export async function startTracking(): Promise<TimeTrack> {
    const { data: response } = await axios.patch('timetrack/start');
    return response;
}

export async function stopTracking(trackId: string, datetime_start: Date, datetime_end?: Date) {
    await axios.post('timetrack/stop', {
        trackId,
        datetime_start,
        datetime_end
    });
}

export async function abortTracking(trackId: string) {
    await axios.post('timetrack/abort', {
        trackId
    });
}

export async function getAdminTimeTracks(userId?: string, datetime_start?: Date): Promise<TimeTrackPopulated[]> {
    const { data: response } = await axios.get(`timetrack/admin?user=${userId ?? ''}&datetime_start=${datetime_start ?? ''}`);
    return response;
}