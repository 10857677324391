import { Alert, Button, Snackbar, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import * as React from 'react';
import { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { CommonButton } from '../components/common/CommonButton';
import { CommonCard } from '../components/common/CommonCard';
import { AuthWrapper } from '../layouts/AuthWrapper';
import {  goSSO, userLogin, userSignup } from "../services";
import { useDispatch } from "react-redux"
import { setUser } from '../reduxToolkit/slices/commonSlice';
import { toast } from 'react-toastify';
import { setToken } from '../helpers/auth';
import { useForm } from 'react-hook-form';
import { TEXTS } from '../texts/german';

interface AuthProps {
    email: string,
    password: string
}

export const LoginPage: React.FC = () => { 

    const queryParams = new URLSearchParams(window.location.search)
    const showLogin = queryParams.get("showLogin") === 'true'; 
    
    const [errorMsg, setErrorMsg] = useState<string>();  
    const [isLoading, setIsLoading] = useState(false); 

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { register, handleSubmit } = useForm<AuthProps>();

    async function onLogin({ email, password }: AuthProps) {
        try {
            const { user, access_token } = await userLogin(email, password)
            setToken(access_token)
            dispatch(setUser(user))
            return navigate('/dashboard');
        } catch (err) {
            console.log("Error login", err)
            toast(TEXTS.ERROR_OCCURED, { type: "error" })
        }
    };

    async function onSignup({ email, password }: AuthProps) {
        try {
            await userSignup(email, password)
            toast(TEXTS.MESSAGE_SIGNUP_SUCCESS, { type: "success" })
            return navigate('/dashboard');
        } catch (err) {
            console.log("Error signup", err)
            toast(TEXTS.ERROR_OCCURED, { type: "error" })
        }
    }; 

    async function ssoLogin(){ 
       setIsLoading(true); 
        try {
            const resUr = await goSSO();
            window.location.replace(resUr)
        } catch (err) {
            console.error(err); 
            setIsLoading(false);
            setErrorMsg('Es ist ein Fehler aufgetreten.');
        }
    }

    return (<div style={{backgroundColor: '#222534', height: '100vh', width: '100vw'}}>  
        <AuthWrapper redirectToLogin={false} redirectToDashboard={true}> 
        
            <Container component="main" maxWidth="xs" style={{display:'flex', flexDirection: 'column'}} className='h-full justify-center items-center'>
                <CssBaseline /> 
                <img src="/stuzubi-logo.png" />
                {showLogin &&<CommonCard title={TEXTS.LOGIN}>
                    <form className='flex flex-col justify-center items-center space-y-2 p-4'>
                        <TextField {...register('email')} variant='outlined' placeholder='email' />
                        <TextField {...register('password')} variant='outlined' placeholder='password' type="password" />
                        <CommonButton className='w-48' onClick={handleSubmit(onLogin)} title={TEXTS.LOGIN} style={{ color: "black" }} />
                        <CommonButton className='w-48' style={{ background: '#24a0ed' }} onClick={handleSubmit(onSignup)} title={TEXTS.SIGNUP} />
                    </form>
                </CommonCard>}  
                <CommonCard title={TEXTS.LOGIN} className='md:mb-32'>  
                    <Button disabled={isLoading} variant="contained" onClick={ssoLogin}>Login</Button>
                </CommonCard>
            </Container>
            <Snackbar open={!!errorMsg} autoHideDuration={5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={() => setErrorMsg(undefined)}>
                <Alert severity="error">{errorMsg}</Alert>
            </Snackbar> 
          
        </AuthWrapper> 
        </div>
    )
};
