import {CommonCard} from "../common/CommonCard"
import {TimeTrackPopulated} from "../../shared/interfaces/common.interface"
import {useEffect, useState} from "react"
import {toast} from "react-toastify"
import {TEXTS} from "../../texts/german"
import {CircularProgress, List, ListItem, ListItemText} from "@mui/material"
import {getAllActiveTimeTracks} from "../../services"

const TrackingUsersCard: React.FC = () => {
    const [activeTracks, setActiveTracks] = useState<TimeTrackPopulated[]>()
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        fetch()
        const interval = setInterval(() => {
            fetch()
        }, 30000);
        return () => clearInterval(interval);
    }, [])

    async function fetch() {
        setIsLoading(true);
        try {
            const activeTracks = await getAllActiveTimeTracks()
            setActiveTracks(activeTracks.sort((a, b) => a?.user?.personioDepartment?.localeCompare(b?.user?.personioDepartment || "") || 0))
        } catch (err) {
            console.error(err);
            // toast(TEXTS.ERROR_OCCURED, { type: "error" })
        }
        setIsLoading(false);
    }

    return (
        <CommonCard
            style={{maxWidth: '500px'}}
            title={"Andere aktive Benutzer"}
            subtitle={"Die Daten werden automatisch alle 30 Sekunden aktualisiert"}>
            {isLoading && <CircularProgress/>}
            {!isLoading && (
                <>
                    {activeTracks && activeTracks?.length < 1 &&
                        <span className="p-4">Es gibt derzeit keine anderen aktiven Benutzer</span>
                    }
                    {activeTracks && activeTracks?.length > 0 &&
                        <List>
                            {activeTracks?.map(track => (
                                <ListItem key={track._id}>
                                    <ListItemText
                                        primary={<><span
                                            className="inline-flex rounded-full h-3 w-3 bg-green-500 mr-5 animate-pulse"></span>{track.user.name ?? track.user.email} | <i>{track.user.personioDepartment}</i></>}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    }
                </>
            )}
        </CommonCard>
    )
}
export default TrackingUsersCard
