import { differenceInMinutes, format } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTimeTrack } from '../reduxToolkit/slices/commonSlice';
import { RootState } from '../reduxToolkit/store';
import { getActiveTimeTrack, getAllActiveTimeTracks, getCurrentDateTimeTracks, startTracking, stopTracking } from '../services';
import { TEXTS } from '../texts/german';

export function useTimeTrack() {
    const activeTimeTrack = useSelector((state: RootState) => state.common.activeTimeTrack)

    const dispatch = useDispatch()

    useEffect(() => {
        _getActiveTimeTrack()
    }, [])

    async function _startTracking() {
        //if a current time track exists, then return it instead of creating new one
        try {
            const currentTimeTrack = await _getActiveTimeTrack()
            if (currentTimeTrack)
                return currentTimeTrack
        } catch (err) {
            console.error(TEXTS.ERROR_OCCURED, err)
        }

        try {
            const timeTrack = await startTracking()
            dispatch(setActiveTimeTrack(timeTrack))
            return timeTrack
        } catch (err) {
            console.error("Error start tracking", err)
        }
    }

    const timeToTodayDate = (time: string) => new Date(`${format(new Date(), 'P')} ${time}`)

    async function _stopTracking(datetime_start: string, datetime_end: string, onSuccess: () => void, onError: (message: string) => void) {
        if (!activeTimeTrack) return;
        const startDate = timeToTodayDate(datetime_start)
        const endDate = datetime_end ? timeToTodayDate(datetime_end) : new Date()

        try {
            await stopTracking(activeTimeTrack._id, startDate, endDate)
            dispatch(setActiveTimeTrack(undefined))
            onSuccess()
        } catch (err: any) {
            onError(err?.response?.data?.error)
            console.error("Error stop tracking", err)
        }
    }

    async function _getActiveTimeTrack() {
        try {
            const timeTrack = await getActiveTimeTrack()
            dispatch(setActiveTimeTrack(timeTrack))
            return timeTrack
        } catch (err) {
            console.error("Error start tracking", err)
        }
    }

    async function _getCurrentDateTimeTracks() {
        try {
            const timeTracks = await getCurrentDateTimeTracks()
            return timeTracks
        } catch (err) {
            console.error("Error get current date tracks", err)
        }
    }

    async function calculateTotalTimeSpent() {
        try {
            const timeTracks = await _getCurrentDateTimeTracks();
            const totalTime = timeTracks?.reduce((pv, cv) => pv + (differenceInMinutes(new Date(cv.datetime_end), new Date(cv.datetime_start))), 0) ?? 0
            if (activeTimeTrack) return totalTime + differenceInMinutes(new Date(), new Date(activeTimeTrack.datetime_start))
            return totalTime
        } catch (err) {
            console.error("Error calculate total time spent", err)
        }
    }

    // custom function because date fns does not return hours minutes format
    function formatDistanceToHoursMinutes(totalMinutes: number) {
        if (totalMinutes < 60) return `0h ${totalMinutes}m`
        const hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes - hours * 60
        return `${hours}h${minutes ? ` ${minutes}m` : ''}`
    }

    return {
        _startTracking,
        _stopTracking,
        _getActiveTimeTrack,
        timeToTodayDate,
        formatDistanceToHoursMinutes,
        calculateTotalTimeSpent
    };
}
