import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import commonReducer, { CommonState } from './slices/commonSlice';

export interface RootState {
  common: CommonState;
}

export default configureStore({
  reducer: {
    common: commonReducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  ],
  devTools: true,
});
